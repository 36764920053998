export const UserData = [
  {
    id: 1,
    year: "Sunday",
    userGain: 2000,
    userLost: 823,
  },
  {
    id: 2,
    year: "Monday",
    userGain: 1000,
    userLost: 345,
  },
  {
    id: 3,
    year: "Tuesday",
    userGain: 1888,
    userLost: 555,
  },
  {
    id: 4,
    year: "Wednesday",
    userGain: 9000,
    userLost: 4555,
  },
  {
    id: 5,
    year: "Thrusday",
    userGain: 4300,
    userLost: 234,
  },
  {
    id: 6,
    year: "Friday",
    userGain: 20300,
    userLost: 234,
  },
  {
    id: 7,
    year: "Saturday",
    userGain: 100,
    userLost: 234,
  },
];

export const Order = [
  { name: "In Progress", code: "P" },
  { name: "Departed", code: "D" },
  { name: "Arrived", code: "AR" },
  { name: "Recent", code: "RE" },
  { name: "This Month Arriving", code: "T" },
  { name: "Next Month Arriving", code: "N" },
  { name: "Next Month Arriving", code: "S" },
];

export const menuItem = [
  { label: " Standard", value: "heading1", type: "heading" },
  { label: "Inprogress", value: "Inprogress", type: "option" },
  { label: "Departed", value: "departed", type: "option" },
  { label: "Arrived", value: "arrived", type: "option" },
  { label: "Created By Me", value: "CR", type: "heading" },
  { label: "Recent", value: "recent", type: "option" },
  { label: "China", value: "china", type: "option" },
  { label: "Created By Other", value: "CR", type: "heading" },
  { label: "This Month Arriving", value: "AR", type: "option" },
  { label: "Next Month Arriving", value: "NX", type: "option" },
  // Add more options, headings, and subheadings as needed
];
export const Product = [
  
  {name:"Global update"},
  {name:" Bulk product addition"},
  {name:"Product subscriptions"},
  {name:"Export found products"},

];
