

export const Shipment = () => {
  return (
    <div className="body-content flex-grow-1 pt-xl-1">
      <div className=" p-3 px-xl-1">
        <div className="d-sm-flex align-items-center flex-wrap header_data">
          <div className="d-flex justify-content-between justify-content-sm-start align-items-center flex-grow-1">
            <h2 className="fz18 me-3 me-md-2 text-blue fw600 mb-0">Shipment</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
