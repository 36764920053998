import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
};


const toogleSlice = createSlice({
  name: "toogle",
  initialState,
  reducers: {
    setShows(state, action) {
      state.show = action.payload;
    },
  },
});

export const { setShows } = toogleSlice.actions;

export default toogleSlice.reducer;
