import Offcanvas from "react-bootstrap/Offcanvas";
import "../Common/SliderMenu.css";
export const SliderMenu = ({
  show,
  setShow,
  title,
  sliderWidth,
  placement,
  body,
  closeIcon,

}) => {
  const handleClose = () => setShow(false);

  return (
    <div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={placement}
        style={{ width: sliderWidth }}
      >
        <Offcanvas.Header
          closeButton
          className={`${closeIcon ? "slider_closebtn" : null}`}
        >
          <Offcanvas.Title>
            <h2>{title}</h2>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{body}</Offcanvas.Body>
  
      </Offcanvas>
    </div>
  );
};
