
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const ProductApi = createApi({
  reducerPath: 'product',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://dummyjson.com/' }),
  tagTypes: ['product'],
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: ({limit, skip}) => {
        return{
          url:  `products`,
           params: {limit, skip},
          providesTags: ['product'],
          transformResponse: (response) => {response?.products.sort((a, b) => b.id - a.id)},
        }
      },     
    }),
    createProducts: builder.mutation({
      query: (data) => {
        return{
            url: 'products',
            method: 'POST',
            body: data,
            headers: {
              'Content-type': 'application/json'
            },
        }
      
      },
      invalidatesTags: ['product'],
    }),
    updateProducts: builder.mutation({
      query: (data) => {
        // const { id } = data
        return {
          url: `products/${data.id}`,
          method: 'PUT',
          body:data,
          headers: {
            'Content-type': 'application/json'
          },
        }
      },
      invalidatesTags: ['product'],
    }),
    deleteProducts: builder.mutation({
      query: (id) => ({
        url: `products/${id}`,
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json'
        },
      }),
      invalidatesTags: ['product'],
    }),
  }),
})

export const { useGetProductsQuery, useCreateProductsMutation, useUpdateProductsMutation, useDeleteProductsMutation } = ProductApi;