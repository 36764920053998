import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { privateRoutes, publicRoutes } from "./AllRoute";
import { Loader } from "./Utils/Loader";

const AppRouter = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const isLoign=false;
  const routes = publicRoutes;
  if (isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const routerConfig = createBrowserRouter(
    routes?.map((route, index) => ({
      path: route.path,
      element: route.element,
      index: index || false,
    }))
  );

  return <RouterProvider router={routerConfig} />;
};

export default AppRouter;
