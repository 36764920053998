import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/img/appjiilogoImg.png";
import { useDispatch, useSelector } from "react-redux";
import { setShows } from "../../Redux/Slice/toogleSlice";
import { SliderMenu } from "../Common/SliderMenu";
import AppItem from "./AppItem";
import { sideBarMenuItem } from "./SideBarMenu";
import { Accordion, Dropdown } from "react-bootstrap";
import Bicon from "../../assets/img/bicon.png";
import Exicon from "../../assets/img/exicon.png";
import Hanicon from "../../assets/img/hanicon.png";
export default function Sidebar() {
  const dispatch = useDispatch();
  const SidebarToggle = useSelector((state) => state?.toogle?.show);
  const handleClick = () => {
    dispatch(setShows(!SidebarToggle));
  };
  const [show, setShow] = useState(false);
  const [closeIcon, setCloseIcon] = useState(false);
  const handleOpen = () => {
    setShow(!show);
    setCloseIcon(true);
  };
  const App = [
    { name: "AppJii", code: "AP", image: Exicon },
    { name: "Buyjii", code: "RM", image: Bicon },
    { name: "Shipjii", code: "LDN", image: Hanicon },
    { name: "EduketX", code: "IST", image: Exicon },
    { name: "Haanjii", code: "PRS", image: Hanicon },
  ];
  const [selectedApp, setSelectedApp] = useState(null);
  console.log();
  const handleSelect = (eventKey) => {
    const selected = App.find((item) => item.name === eventKey);
    setSelectedApp(selected);
  };
  return (
    <aside id="asidebar">
      <div className="position-relative">
        <div className="d-flex align-items-center">
          <div className="sidebar-sec-nav">
            {/* <i className="fa-regular fa-bars grip-dot" onClick={handleOpen}></i> */}
            <div className="toogle_btn">
              <i className="fa-regular fa-bars" onClick={handleClick}></i>
            </div>
            <SliderMenu
              show={show}
              setShow={setShow}
              placement="start"
              sliderWidth="250px"
              closeIcon={closeIcon}
              setCloseIcon={setCloseIcon}
              body={<AppItem />}
            />
          </div>
          <div className="logo py-sm-2 px-2">
            <Link to="/dashboard">
              <img src={Logo} className="lg-logo" alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="nav-sidebar d-flex flex-column ">
        <nav className="flex-grow-1">
          <ul className="d-flex flex-column h-100 pb-4">
            {sideBarMenuItem.map((item, i) => (
              <li key={i}>
                <NavLink to={item.path}>
                  <i className={item.icon}></i> <span> {item.label}</span>
                </NavLink>
              </li>
            ))}
            
            <div className="mt-auto appselect col-lg-12 position-absolute bottom-0">
              <Dropdown
                onSelect={handleSelect}
                placement="top-start"
                className=""
              >
                {!SidebarToggle ? (
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="w-100 d-flex justify-content-between align-items-center app_item"
                  >
                    {selectedApp ? selectedApp.name : "Select App"}
                  </Dropdown.Toggle>
                ) : (
                  <div className="toogle_btn">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="hide_appbtn"
                    >
                      <i className="fa-light fa-grid-round grip-dot"></i>
                    </Dropdown.Toggle>
                  </div>
                )}

                <Dropdown.Menu className="w-100 animate slideIn">
                  {App?.map((item, index) => {
                    return (
                      <div className="brand-list" key={index}>
                        <Dropdown.Item eventKey={item.name}>
                          <img src={item.image} alt="app" /> {item.name}
                        </Dropdown.Item>
                      </div>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>

       
          </ul>
        </nav>
      </div>
    </aside>
  );
}
