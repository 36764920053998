// ReusableDataTable.jsx
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../DataTables/DataTable.css";
import { Paginator } from "primereact/paginator";

export const DataTables = (props) => {
  const {
    columns,
    first,
    rows,
    totalRecords,
    onPageChange,
    rowsPerPageOptions,
    showCustomPaginator,
    paginatorTemplate,
    currentPageReportTemplate,
  } = props;
  return (
    <>
      <div className="datatable-wrapper">
        <DataTable {...props} dataKey="id">
          {columns.map((column, index) => (
            <Column key={index} {...column} />
          ))}
        </DataTable>
      </div>
      {showCustomPaginator && (
        <div className="paginator-wrapper" style={{ float: "right" }}>
          <Paginator
            style={{ float: "right", paddingRight: 0 }}
            first={first}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={onPageChange}
            rowsPerPageOptions={rowsPerPageOptions}
            template={paginatorTemplate}
            currentPageReportTemplate={currentPageReportTemplate}
          />
        </div>
      )}
    </>
  );
};
