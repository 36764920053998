export const sideBarMenuItem = [
    {
    
      label: "Dashboard",
      icon: "fa-light fa-gauge-high",
      path: "/dashboard"
    },
    {
      
      label: "Orders",
      icon: "fa-light fa-file-invoice",
      path: "/orders"
    },
    {
   
      label: "Products",
      icon: "fa-light fa-box-open-full",
      path: "/products"
    },
    {
      label:"Shipment",
      path:"/shipment",
      icon:"fa-light fa-ship"
  },
    {
      label:"Containers",
      path:"/containers",
      icon:"fa-light fa-container-storage"
  },
    {
      label:" Documents",
      path:"/documents",
      icon:"fa-light fa-file-contract",
  },
    {
      label:"Couriers",
      path:"/couriers",
      icon:"fa-light fa-truck-fast",
  },
    {
      label:"Offers",
      path:"/offers",
      icon:"fa-light fa-badge-dollar",
  },
    {
      label:"Report",
      path:"/report",
      icon:"fa-light fa-file-chart-column",
  },
    {
      label:"Partners",
      path:"/partners",
      icon:"fa-light fa-user-circle",
  },
    {
      label:"Contacts",
      path:"/contacts",
      icon:"fa-light fa-address-book",
  },
    {
    
      label: "Message Center",
      icon: "fa-light fa-comment-lines",
      path: "/messagecenter"
    },
    {
     
      label: "Product Bundles",
      icon: "fa-light fa-file-contract",
      path: "/productbundles"
    },
    {
   
      label: "Accounting",
      icon: "fa-light fa-receipt",
      path: "/accounting"
    },
    {
    
      label: "Settings",
      icon: "fa-light fa-gear",
      path: "/settings"
    }
  ];
  
   