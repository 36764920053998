import React, {useState} from "react";
import {Form} from 'react-bootstrap'

export default function CustomSelect({ options, onChange }) {
  console.log("options",options);
  const [value, setValue] = useState()
  const handelChange = (e)=>{
    onChange(e.target.value)
    setValue(e.target.value)
  }
    return(
        <Form.Select value={value} onChange={handelChange} className="form-control border-0">
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Select>
    )
}