import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
    <footer className="main-footer shadow">
        <div className="container-fluid p-0">
            <div className="d-flex justify-content-between align-items-center footer_section">               
                <p className="fz14 mb-0 ">© Appjii</p>
                <ul className="d-flex align-items-center">
                    <li className="fz14 text-dark  me-3">Need Help</li>
                    <li className="me-2"><Link to="" className=" fz16 text-dark  "><i className="fa-light fa-headphones"></i></Link></li>
                    <li><Link to="" className=" fz16 text-dark "><i className="fa-light fa-circle-question"></i></Link></li>
                </ul>
            </div>
        </div>
    </footer>
    
    </>
  )
}
