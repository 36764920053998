import React from "react";
import { Link } from "react-router-dom";


export default function FilterButton({onClick, filter }) {


    return(
        <Link to="#" onClick={() => onClick(filter)} >{filter}</Link>
    )
}