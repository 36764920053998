
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  useCreateOrderMutation,
  useUpdateOrderMutation,
} from "../../Redux/Action/ordersAction";
import { PulseLoader } from "react-spinners";
import { NotificationManager } from "react-notifications";
import { useState } from "react";

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  status: Yup.string().required("Status is required"),
  orderdate: Yup.date().required("Order Date is required"),
  customer: Yup.string().required("Customer is required"),
  phone: Yup.string().required("Phone is required"),
  total: Yup.string().required("Total is required"),
});

export const AddOrders = ({ setShow, orderItem, setOpen ,open}) => {
  const initialValues = {
    status: orderItem ? orderItem.status : "",
    orderdate: orderItem ? orderItem.orderdate : "",
    customer: orderItem ? orderItem.customer : "",
    phone: orderItem ? orderItem.phone : "",
    total: orderItem ? orderItem.total : "",
    id: orderItem ? orderItem.id : "",
  };
  const [createOrder, { isLoading: createOrderLoading }] =
    useCreateOrderMutation();
  const [updateOrder, { isLoading: updateOrderLoading }] =
    useUpdateOrderMutation();
  const handleSubmit = async (values, { resetForm }) => {
    try {
      {
        !open ? await createOrder(values) : await updateOrder(values);
      }
      NotificationManager.success(
        !open ? "Order created successfully" : "Order Update successfully"
      );
      resetForm();
      setShow(false);
    } catch (error) {
      NotificationManager.error(!open?"Failed to create order":"Failed to update order");
    }
  };
  const [date, setDate] = useState(null);
  return (
    <div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, errors, touched }) => (
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Form>
                  {/* Status Field */}
                  <div className="row pb-4">
                    <div className="col-lg-6">
                      <label htmlFor="status" className="lable_txt">
                        Status:
                      </label>
                      <Field
                        id="status"
                        className="form-control"
                        name="status"
                        validate={(value) =>
                          value ? undefined : "Status is required"
                        }
                      />
                      {errors.status && touched.status && (
                        <div className="error">{errors.status}</div>
                      )}
                    </div>
                    {/* Order Date Field */}
                    <div className="col-lg-6">
                      <label htmlFor="orderdate" className="lable_txt">
                        Order Date:
                      </label>
                      <Field
                        id="orderdate"
                        className="form-control"
                        name="orderdate"
                        type="date"
                      />
                      {errors.orderdate && touched.orderdate && (
                        <div className="error">{errors.orderdate}</div>
                      )}
                    </div>
                    
                  </div>

                  {/* Customer Field */}
                  <div className="row pb-4">
                    <div className="col-lg-6">
                      <label htmlFor="customer" className="lable_txt">
                        Customer:
                      </label>
                      <Field
                        id="customer"
                        className="form-control"
                        name="customer"
                      />
                      {errors.customer && touched.customer && (
                        <div className="error">{errors.customer}</div>
                      )}
                    </div>
                    {/* Phone Field */}
                    <div className="col-lg-6">
                      <label htmlFor="phone" className="lable_txt">
                        Phone:
                      </label>
                      <Field id="phone" className="form-control" name="phone" />
                      {errors.phone && touched.phone && (
                        <div className="error">{errors.phone}</div>
                      )}
                    </div>
                  </div>

                  {/* Total Field */}
                  <div className="col-lg-6">
                    <label htmlFor="total" className="lable_txt">
                      Total:
                    </label>
                    <Field id="total" className="form-control" name="total" />
                    {errors.total && touched.total && (
                      <div className="error">{errors.total}</div>
                    )}
                  </div>

                  {/* Submit Button */}
                  <div className="btn-submit mt-3 offcanvsfooter">
                    <button
                      className="btn btn-primary fz14 btn-blue text-white ms-2"
                      type="submit"
                    >
                      {!createOrderLoading && !updateOrderLoading ? (
                        "Submit"
                      ) : (
                        <PulseLoader color="#e8eeed" />
                      )}
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};
