// authActions.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import {setAuthorizationToken} from "../Slice/setAuthorizationToken";
import { api } from '../Api/Api';

export const loginUser = createAsyncThunk(
  'auth/loginUser',
  async (user) => {
    try {
      const res = await axios.post(`${api}/auth/login`, user);
      const token=res?.data?.token
      if (token) {
        localStorage.setItem("token", token);
        const decodedToken = jwtDecode(token);
        setAuthorizationToken(token);
        return decodedToken;
      } else {
        throw new Error('Token not found');
      }
    } catch (err) {
      throw new Error(err.message);
    }
  }
);




