import { InputText } from "primereact/inputtext";
import Dropdown from "react-bootstrap/Dropdown";
import "../DropDownMenuWithButton/DropDownButton.css"

export const DropDownMenuWithButton = ({
  options,
  isOpen,
  searchTerm,
  handleSearchChange,
  toggleDropdown,
  handleNewViewClick,
}) => {
  return (
    <>
      <button
        onClick={toggleDropdown}
        type="button"
        id="react-aria1169772345-64"
        aria-expanded="false"
        className="d-block text-green dropdown-icon-none  border-0 btn"
      >
        <i className="fa-regular fa-plus"></i> Add View (3 / 5)
      </button>
      <Dropdown
        show={isOpen}
        onToggle={toggleDropdown}
        className="custom-dessign-dropdown"
      >
        <Dropdown.Menu className="pb-0 animates slideIns">
          <div className="form-group border-bottom pb-2 px-2 py-2 position-relative">
            <InputText
              type="text"
              placeholder="Search"
              className="form-control"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button className="insideSearchBtn fz14 bg-transparent border-0 position-absolute">
              <i className="fa-light fa-search"></i>
            </button>
          </div>
          {/* 
        {options?.map((option, index) => (
          <Dropdown.Item key={index} eventKey={option.value} className="menu_list">
            {option.label}
          </Dropdown.Item>
        ))} */}
          {options?.map((option, index) => {
            if (option.type === "heading") {
              return (
                <Dropdown.Item key={index} className="menu_heading">
                  {option.label}
                </Dropdown.Item>
              );
            } else {
              return (
                <Dropdown.Item
                  key={index}
                  eventKey={option.value}
                  className="menu_list"
                >
                  {option.label}
                </Dropdown.Item>
              );
            }
          })}

          <div className="border-top mt-1 addview_btn">
            <button
              type="button"
              className="bg-transparent border-0 text-green px-3 py-2 fz14 fw600 btn"
              onClick={handleNewViewClick}
            >
              <i className="fa-regular fa-plus"></i> New View
            </button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
