import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
export default function OrderListDash(props) {
    const [rowClick, setRowClick] = useState(true);
    const [selectedProducts, setSelectedProducts] = useState(null);
//    console.log(props);
        
   return(
    <>
    <div className="flex justify-content-center align-items-center mb-4 gap-2 d-none">
    <InputSwitch inputId="input-rowclick" checked={rowClick} onChange={(e) => setRowClick(e.value)} />
    <label htmlFor="input-rowclick">Row Click</label>
     </div>
    <DataTable selection={selectedProducts} stripedRows onSelectionChange={(e) => setSelectedProducts(e.value)} value={props.products} scrollable scrollHeight='300' rows={10} paginator showGridlines={false} rowsPerPageOptions={[10, 25, 50]} dataKey="id" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries">
    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
    <Column sortable field="id" header="ID" />
    <Column sortable field="status" header="Status" />
    <Column sortable field="orderDate" header="Order Date" />
    <Column sortable field="customer" header="Customer" />
   </DataTable>
   </>
   )
}