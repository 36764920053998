import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./assets/css/media.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/all.min.css";
import "./assets/css/style.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "react-notifications/lib/notifications.css";
import "./assets/css/Form.css";
import App from "./App";
import { store } from "./Redux/Store/store";
import { NotificationContainer } from "react-notifications";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <Auth0Provider
      domain="dev-rr1b5ut2sfbcl7qr.us.auth0.com"
      clientId="X70gA8hOXsCK4ROLSRkkSivGcVnjkNHc"
      authorizationParams={{
        redirect_uri: "http://localhost:3000/dashboard",
      }}
    >
      <Provider store={store}>
        <App />
        <NotificationContainer />
      </Provider>
    </Auth0Provider>
  </>
);
