import { jwtDecode } from "jwt-decode";
import "./App.css";
import AppRouter from "./AppRoute";
import { setCurrentUser } from "./Redux/Slice/AuthSlice";
import { store } from "./Redux/Store/store";
function App() {
  const token = localStorage.getItem("token");
  if (token) {
    const decodedToken = jwtDecode(token);
    store.dispatch(setCurrentUser(decodedToken));
  }
  return <AppRouter />;
}

export default App;
