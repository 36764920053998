export const OrderTable = {
    getProductsData() {
        return [
            {
                id: 'Order #908',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #988',
                status: 'Close',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #989',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #981',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #982',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #983',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #984',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #985',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #986',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #987',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #988',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #989',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #1000',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #1001',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #1002',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #1003',
                status: 'Failed',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #1004',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #1005',
                status: 'Declined',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #1006',
                status: 'Canceled',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9811',
                status: 'Complete',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #98022',
                status: 'Awaiting call',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #98033',
                status: 'Backordered',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #98044',
                status: 'AwaitingCall',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9805',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9806',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9807',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9808',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9809',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #98000',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9810',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9811',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9812',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #9813',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #914',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },
            {
                id: 'Order #980',
                status: 'Open',
                orderDate: `12/6/2022`,
                customer: '@ Nills George',
                phone: `+1 646-386-3600`,
                setting: '65',
                total: '$6,020.00',
            },

        ];
    },

    
    getProductsMini() {
        return Promise.resolve(this.getProductsData().slice(0, 5));
    },

    getProductsSmall() {
        return Promise.resolve(this.getProductsData().slice(0, 10));
    },

    getProducts() {
        return Promise.resolve(this.getProductsData());
    },

    getProductsWithOrdersSmall() {
        return Promise.resolve(this.getProductsWithOrdersData().slice(0, 10));
    },

    getProductsWithOrders() {
        return Promise.resolve(this.getProductsWithOrdersData());
    }
};