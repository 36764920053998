import Swal from "sweetalert2";

const ConfirmationDialog = ({ title, text, onConfirm, show,onCancel }) => {
  const handleConfirmation = () => {
    Swal.fire({
      title: title,
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      }else{
        onCancel()
      }
    });
  };

  if (show) {
   
    handleConfirmation();
  }

  return null;
};

export default ConfirmationDialog;
