import { AiOutlineDashboard } from "react-icons/ai";
import { FaRegFileAlt } from "react-icons/fa";
import { FaBoxOpen } from "react-icons/fa";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { FaFileContract } from "react-icons/fa6";
import { CiReceipt } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { IoKeyOutline } from "react-icons/io5";
import { CiUser } from "react-icons/ci";
import { IoLogOutOutline } from "react-icons/io5";
import { CiCircleChevLeft } from "react-icons/ci";
import { CiCircleChevRight } from "react-icons/ci";
export const dashIcon=<i className="fa-light fa-gauge-high"></i> 
export const orders=<i className="fa-light fa-file-invoice"></i>
export const product=<i className="fa-light fa-cubes"></i>
export const messsageCenter=<i className="fa-light fa-comment-dots"></i>
export const productbundles=<i className="fa-light fa-cubes"></i>
export const Shipment=<i className="fa-light fa-ship"></i>
export const Receipt=<CiReceipt />
export const settings=<IoSettingsOutline />
export const key=<IoKeyOutline />
export const user=<CiUser />
export const logOutIcon=<IoLogOutOutline />
export const leftIcon=<CiCircleChevLeft />
export const RightIcon=<CiCircleChevRight />