import React, { useEffect, useState } from "react";
import Logo from "../../assets/img/appjiilogo.png";
import crm from "../../assets/img/crm.png";
import anylist from "../../assets/img/anylist.png";
import invoice from "../../assets/img/invoice.png";
import stock from "../../assets/img/stock.png";
import asset from "../../assets/img/asset.png";
import order from "../../assets/img/order.png";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import "../Auth/Login.css";
import { Tab, Tabs } from "react-bootstrap";
export default function Login() {
  const [registration, setRegistration] = useState(false);
  document.title = "Login";
  useEffect(() => {
    if (!registration) {
      document.title = "Login | Appjii";
    } else {
      document.title = "Registration | Appjii";
    }
  }, [registration]);
  const { loginWithRedirect } = useAuth0();

  const CreateAccount = () => {
    setRegistration(true);
  };
  const Login = () => {
    setRegistration(false);
  };
  return (
    <section className="login-page-bg d-flex flex-column  justify-content-between">
      <div className="login-box bg-white rounded container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <div className="login-wrap">
              <figure className="d-block mb-5 text-centr">
                <img src={Logo} className="img-fluid" alt="" />
              </figure>
              {!registration ? (
                <div className="login_section">
                  <h3 className="fz28 fw800 mb-5">Log in to your account</h3>
                  <div className="selection_tab">
                    <Tabs
                      defaultActiveKey="email"
                      id="uncontrolled-tab-example"
                      className="mb-3 nav_menu"
                    >
                      <Tab eventKey="email" title="Email" className="nav_menu">
                        <form action="" className="pt-4">
                          <div className="mb-4">
                            <label className="pb-2">Bussiness Email</label>
                            <div className="input-group flex-nowrap">
                              <input
                                type="text"
                                className="shadow-none rounded form-control fz18 px-0"
                                // placeholder="Email Address"
                              />
                            </div>
                          </div>
                          <div className="mb-5">
                            <label className="pb-2">Password</label>
                            <div className="input-group flex-nowrap">
                              <input
                                type="password" // Changed to type password for security
                                className="shadow-none rounded form-control fz18 px-0"
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-7">
                              <div className="form-check ps-0 d-flex align-items-center form-check-inline">
                                <Link className="forgot_password" to="/forget">
                                  Forgot Password ?{" "}
                                </Link>
                              </div>
                            </div>
                          </div>
                          <Link
                            to="/dashboard"
                            className="bg-green btn fz14 fz18  mt-5 py-2 text-white w-100"
                          >
                            Login
                          </Link>
                        </form>
                        <div className="mt-4 account_create">
                          <span>New to appjii ? </span>
                          <Link
                            to=""
                            className="create_account"
                            onClick={CreateAccount}
                          >
                            Create Your Free account
                          </Link>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="mobile"
                        title="Mobile Number"
                        className="mobileno"
                      >
                        <div className="mb-4 pt-4">
                          <label className="pb-2">Mobile Number</label>
                          <div className="input-group flex-nowrap">
                            <input
                              type="text"
                              className="shadow-none rounded form-control fz18 px-0"
                              // placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="login_btn">
                          <Link
                            to="/dashboard"
                            className="bg-green btn fz14 fz18  mt-5 py-2 text-white w-100"
                          >
                            Login
                          </Link>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              ) : (
                <div className="registration_section">
                  <h3 className="fz28 fw800 mb-5">Create your free account</h3>
                  <div className="selection_tab">
                    <Tabs
                      defaultActiveKey="email"
                      id="uncontrolled-tab-example"
                      className="mb-3 nav_menu"
                    >
                      <Tab eventKey="email" title="Email" className="nav_menu">
                        <form action="" className="pt-4">
                          <div className="mb-4">
                            <label className="pb-2">Legal first name</label>
                            <div className="input-group flex-nowrap">
                              <input
                                type="text"
                                className="shadow-none rounded form-control fz18 px-0"
                                // placeholder="Email Address"
                              />
                            </div>
                          </div>
                          <div className="mb-4">
                            <label className="pb-2">Legal last name</label>
                            <div className="input-group flex-nowrap">
                              <input
                                type="text"
                                className="shadow-none rounded form-control fz18 px-0"
                                // placeholder="Email Address"
                              />
                            </div>
                          </div>
                          <div className="mb-4">
                            <label className="pb-2">Business email</label>
                            <div className="input-group flex-nowrap">
                              <input
                                type="text"
                                className="shadow-none rounded form-control fz18 px-0"
                                // placeholder="Email Address"
                              />
                            </div>
                          </div>
                          <div className="mb-5">
                            <label className="pb-2">Password</label>
                            <div className="input-group flex-nowrap">
                              <input
                                type="password" // Changed to type password for security
                                className="shadow-none rounded form-control fz18 px-0"
                              />
                            </div>
                          </div>

                          <Link
                            to="/dashboard"
                            className="bg-green btn fz14 fz18  mt-2 py-2 text-white w-100"
                          >
                            Get started
                          </Link>
                        </form>
                        <div className="mt-4 account_create">
                          <span>Already have an account? </span>
                          <Link
                            to=""
                            className="create_account"
                            onClick={Login}
                          >
                            Login
                          </Link>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="mobile"
                        title="Mobile Number"
                        className="mobileno"
                      >
                        <div className="mb-4 pt-4">
                          <label className="pb-2">Mobile Number</label>
                          <div className="input-group flex-nowrap">
                            <input
                              type="text"
                              className="shadow-none rounded form-control fz18 px-0"
                              // placeholder="Email Address"
                            />
                          </div>
                        </div>
                        <div className="login_btn">
                          <Link
                            to="/dashboard"
                            className="bg-green btn fz14 fz18  mt-5 py-2 text-white w-100"
                          >
                            Login
                          </Link>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              )}
              {/* <div className="text-center mt-5">
                <button
                  type="button"
                  className="login-with-google-btn"
                  onClick={() => loginWithRedirect()}
                >
                  Sign in with Google
                </button>
              </div> */}
              <ul className="d-flex justify-content-start home_footer">
                <li>
                  <Link to="" className="fz14 me-2">
                    Legal & Privacy &nbsp;
                  </Link>
                </li>
                <li>
                  <Link to="" className="fz14 me-2">
                    Help Centre
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6 order-sm-2 d-flex">
            <div className="feature">
              <div className="feature_content">
                <div className="app_feature">
                  <ul>
                    <li>
                      <img src={crm} alt="crm" />
                      <div className="feature_item">
                        <h5>CRM</h5>
                        <p>
                          Comprehensive CRM platform for customer-facing teams.
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src={anylist} alt="crm" />
                      <div className="feature_item">
                        <h5>Analytics</h5>
                        <p>
                          Powerful accounting platform for growing businesses.
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src={invoice} alt="crm" />
                      <div className="feature_item">
                        <h5>Invoicing</h5>
                        <p>
                          Powerful accounting platform for growing businesses.
                        </p>
                      </div>
                    </li>
                    <li>
                      <img src={asset} alt="crm" />
                      <div className="feature_item">
                        <h5>Asset Management</h5>
                        <p>Secure email service for your business.</p>
                      </div>
                    </li>
                    <li>
                      <img src={stock} alt="crm" />
                      <div className="feature_item">
                        <h5>Stock</h5>
                        <p>Build powerful custom applications faster.</p>
                      </div>
                    </li>
                    <li>
                      <img src={order} alt="crm" />
                      <div className="feature_item">
                        <h5>Order Management</h5>
                        <p>Build powerful custom applications faster.</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="footer_right_content">
                  <p className="fz14 text-center text-lg-start mb-0">
                    &copy; appjii 2024. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
