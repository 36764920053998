import React from "react";

import ApppLogo from '../../assets/img/appjiilogo.png';
import Bicon from '../../assets/img/bicon.png';
import Exicon from '../../assets/img/exicon.png';
import Hanicon from '../../assets/img/hanicon.png';
import { Link } from "react-router-dom";
const AppItem = () => {
  return (
    <div>
      <span className="dropdown-fixed px-3">
        <img src={ApppLogo} className="slider-logo" alt="" />
        <div className="brand-list">
          <Link className="dropdown-item" to="#">
            <img src={Exicon} alt="" /> EduketX
          </Link>
          <Link className="dropdown-item" to="#">
            <img src={Bicon} alt="" /> Buyjii
          </Link>
          <Link className="dropdown-item" to="#">
            <img src={Hanicon} alt="" /> Haanjii
          </Link>
          <Link className="dropdown-item" to="#">
            <img src={Bicon} alt="" /> Shipjii
          </Link>
          <Link className="dropdown-item" to="#">
            <img src={Exicon} alt="" /> EduketX
          </Link>
          <Link className="dropdown-item" to="#">
            <img src={Bicon} alt="" /> Buyjii
          </Link>
          <Link className="dropdown-item" to="#">
            <img src={Hanicon} alt="" /> Haanjii
          </Link>
          <Link className="dropdown-item" to="#">
            <img src={Bicon} alt="" /> Shipjii
          </Link>
        </div>
      </span>
    </div>
  );
};

export default AppItem;
