import React, { useState, useEffect } from "react";
import Customblocks1 from "../../assets/img/custom_blocks_1.svg";
import Customblocks2 from "../../assets/img/custom_blocks_2.svg";
import Customblocks3 from "../../assets/img/custom_blocks_3.svg";
import Graph from "../../assets/img/graph.jpg";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import SelectDatatable from "./datatable/SelectDatatable";
import OrderListDash from "./datatable/OrderListDash";
import FilterButton from "./FilterButton";
import { OrderTable } from "../../services/OrderTable";
import OrderList from "./datatable/OrderList";
import { UserData } from "../../services/Data";
import { LineChart, PieCharts } from "../Common/LineChart";

export default function Dashboard() {
 useEffect(()=>{
  document.title = "Dashboard | Appjii";
 },[])
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Open");

  // useEffect(() => {
  //     setProducts(OrderListDash);
  //     setFilteredProducts(OrderListDash);
  //   }, []);

  const handleFilterClick = (filter) => {
    setActiveFilter(filter);
  };

  useEffect(() => {
    const filtered = OrderTable.getProductsData().filter(
      (val) => val.status === activeFilter
    );
    setFilteredProducts(filtered);
  }, [activeFilter]);

  const labels = UserData.map((data) => data.year);
  const datas = {
    labels: labels,
    datasets: [
      {
        label: "Sales",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: "#017eff",
        tension: 0.1,
      },
    ],
  };
  const data = {
    labels: [
      'Red',
      'Blue',
      'Yellow'
    ],
    datasets: [{
      label: 'Booking',
      data: [300, 50, 100],
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'
      ],
      hoverOffset: 4
    }]
  };
  return (
    <div className="body-content flex-grow-1 pt-xl-1">
      <div className="p-3 px-xl-1">
      <h2 className="custom-blocks-section__title header_data text-blue">
              Dashboard
            </h2>
        <div className="dashboardTabs">
          <section>
       
            <div className="row">
              <div className="col-lg-4  col-sm-12">
                <div className="analytics-card">
                  <div className="analytics-card__preheader cm-date-range__selected-date-text">
                    Mar 01, 2024 — Apr 01, 2024
                  </div>
                  <div className="analytics-card__header my-4">
                    <h3 className="analytics-card__title">Sales</h3>
                    <Link to="#" className="analytics-card__title-button">
                      View reports
                    </Link>
                  </div>
                  <div className="analytics-card__numbers">
                    <div className="analytics-card__number">
                      $<span>0.00</span>
                    </div>
                    <div className="analytics-card__number-dynamics ">∞</div>
                  </div>
                  {/* <figure className='mt-4'><img src={Graph} className="img-fluid w-100" alt=""/></figure> */}
                  <div className="col-md-12 col-lg-12">
                    <LineChart chartData={datas} />
                  </div>
                </div>
                <div className="analytics-card mt-5">
                  <div className="analytics-card__header">
                    <h3 className="analytics-card__title">Active products</h3>
                    <Link to="#" className="analytics-card__title-button">
                      View products
                    </Link>
                  </div>
                  <div className="analytics-card__numbers">
                    <div className="analytics-card__number">16</div>
                  </div>
                  <div className="analytics-card-resource-list">
                    <div className="analytics-card-resource-list__title">
                      Products details
                    </div>
                    <div className="analytics-card-resource-list__content">
                      <div className="analytics-card-resource-list__item ">
                        <div className="analytics-card-resource-list__item-content">
                          <div className="analytics-card-resource-list__primary">
                            <div className="analytics-card-resource-list__name">
                              <Link to="#">Out of stock</Link>
                            </div>
                            <Link to="#">1</Link>
                          </div>
                        </div>
                      </div>
                      <div className="analytics-card-resource-list__item ">
                        <div className="analytics-card-resource-list__item-content">
                          <div className="analytics-card-resource-list__primary">
                            <div className="analytics-card-resource-list__name">
                              <Link to="#">On moderation</Link>
                            </div>
                            <Link to="#">0</Link>
                          </div>
                        </div>
                      </div>
                      <div className="analytics-card-resource-list__item ">
                        <div className="analytics-card-resource-list__item-content">
                          <div className="analytics-card-resource-list__primary">
                            <div className="analytics-card-resource-list__name">
                              <Link to="#">Disapproved</Link>
                            </div>
                            <Link to="#">0</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  col-sm-12">
                <div className="analytics-card">
                  <div className="analytics-card__header">
                    <h3 className="analytics-card__title">Recent orders</h3>
                    <Link to="#" className="analytics-card__title-button">
                      View orders
                    </Link>
                  </div>
                  <div className="cm-j-tabs cm-track tabs">
                    <div className="order-catg-list">
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("All");
                        }}
                        filter="All"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("Close");
                        }}
                        filter="Close"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("Complete");
                        }}
                        filter="Complete"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("Open");
                        }}
                        filter="Open"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("Failed");
                        }}
                        filter="Failed"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("Declined");
                        }}
                        filter="Declined"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("Backordered");
                        }}
                        filter="Backordered"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("Canceled");
                        }}
                        filter="Canceled"
                      />
                      <FilterButton
                        onClick={() => {
                          handleFilterClick("AwaitingCall");
                        }}
                        filter="Awaiting call"
                      />
                    </div>
                  </div>
                  <div className="analytics-card-resource-list__no-items no-items">
                    <OrderListDash products={filteredProducts} />
                  </div>
                </div>
              </div>
              <div className="col-lg-4  col-sm-12">
                <div className="analytics-card mb-5">
                  <div className="analytics-card__header">
                    <h3 className="analytics-card__title">Current balance</h3>
                    <Link to="#" className="analytics-card__title-button">
                      View accounting
                    </Link>
                  </div>
                  <div className="analytics-card__numbers">
                    $<span>3,719.00</span>
                  </div>
                  <Link className="btn btn-primary cm-combination">
                    Refill balance
                  </Link>
                </div>
                <div className="analytics-card">
                  <div className="analytics-card__header">
                    <h3 className="analytics-card__title">
                      Products used on the plan
                    </h3>
                    <Link to="#" className="analytics-card__title-button">
                      View plan
                    </Link>
                  </div>
                 
                  <PieCharts chartData={data}/>
                </div>
              </div>
            </div>
          </section>
          <section className="my-5">
            {/* <SelectDatatable /> */}
            <OrderList />
          </section>
          <section className="custom-blocks-section">
            <h2 className="custom-blocks-section__title">Onboarding Guide</h2>
            <div className="row g-5">
              <div className="col-lg-4  col-sm-12">
                <div className="custom-blocks-card mt-4">
                  <div className="custom-blocks-card__actions">
                    <div className="order-4">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="dropdown-basic"
                          className="fz16 shadow-none dropdown-toggle-none"
                        >
                          <i className="fa-light fa-ellipsis"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-with-search">
                          <Dropdown.Item>
                            <Link className="dropdown-item" to="#">
                              Dismiss
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="custom-blocks-card__inner d-flex">
                    <div>
                      <h4>1. Set up your profile</h4>
                      <p>
                        Upload your logo, tell the customers about yourself, and
                        leave your contact and legal information.
                      </p>
                      <p>
                        <Link to="#" className="btn">
                          Go to your profile
                        </Link>
                      </p>
                    </div>
                    <img src={Customblocks1} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4  col-sm-12">
                <div className="custom-blocks-card mt-4">
                  <div className="custom-blocks-card__actions">
                    <div className="order-4">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="dropdown-basic"
                          className="fz16 shadow-none dropdown-toggle-none"
                        >
                          <i className="fa-light fa-ellipsis"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-with-search">
                          <Dropdown.Item>
                            <Link className="dropdown-item" to="#">
                              Dismiss
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="custom-blocks-card__inner d-flex">
                    <div>
                      <h4>2. Add your products</h4>
                      <p>
                        List your products by hand or upload a CSV or XML file
                        to import products in bulk.
                      </p>
                      <p>
                        <Link to="#" className="btn">
                          Add one product
                        </Link>
                      </p>
                    </div>
                    <img src={Customblocks2} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4  col-sm-12">
                <div className="custom-blocks-card mt-4">
                  <div className="custom-blocks-card__actions">
                    <div className="order-4">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="dropdown-basic"
                          className="fz16 shadow-none dropdown-toggle-none"
                        >
                          <i className="fa-light fa-ellipsis"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-with-search">
                          <Dropdown.Item>
                            <Link className="dropdown-item" to="#">
                              Dismiss
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div className="custom-blocks-card__inner d-flex">
                    <div>
                      <h4>3. Set up shipping</h4>
                      <p>
                        Let customers choose their preferred shipping method,
                        and charge them for delivery. Calculate rates for FedEx,
                        DHL and the likes automatically, set a flat rate, or let
                        customers pick up orders from your store.
                      </p>
                      <p>
                        <Link to="#" className="btn">
                          Go to shipping methods
                        </Link>
                      </p>
                    </div>
                    <img src={Customblocks3} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
