import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import {
  useDeleteOrderMutation,
  useGetOrdersQuery,
} from "../../../Redux/Action/ordersAction";
import { SliderMenu } from "../../Common/SliderMenu";
import { AddOrders } from "../../Orders/AddOrders";
import { Loader } from "../../../Utils/Loader";
import DeleteConfirmationDialog from "../../Common/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";

const OrderList = React.memo(() => {
  const [rowClick, setRowClick] = useState(true);
  const [selectedProducts, setSelectedProducts] = useState(null);

  const [orderItem, setOrderItem] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null);

  const editProduct = (rowData) => {
    setOrderItem(rowData);
    setOpen(!open);
  };
const cancelItem=()=>{
  setDeleteTarget(null)
}
  const [deleteOrder] =
    useDeleteOrderMutation();

  const { data: orders, error, isLoading } = useGetOrdersQuery();

  useEffect(() => {
    if (error) {
      NotificationManager.error(error?.error);
    }
  }, [error]);

  const confirmDelete = (rowData) => {
    setDeleteTarget(rowData);
  };

  const handleDeleteConfirmation = async () => {
    setDeleteTarget(null);
    try {
      await deleteOrder(deleteTarget?.id);
      NotificationManager.success("Order delete successfully");
    } catch (error) {
      NotificationManager.error("Failed to delete order");
    }
  };
  const result = useSelector((state) => state?.orders);
  const { searchData, isSearch } = result;
  const filteredOrders = orders?.filter(
    (order) =>
      (order.customer ? order.customer.toLowerCase() : "").includes(
        searchData?.toLowerCase()
      ) ||
      (order.status ? order.status.toLowerCase() : "").includes(
        searchData?.toLowerCase()
      ) ||
      (typeof order.phone === "string"
        ? order.phone.toLowerCase()
        : ""
      ).includes(searchData?.toLowerCase()) ||
      (searchData === "pending" &&
        order.status &&
        order.status.toLowerCase() === "pending") ||
      (searchData === "confirmed" &&
        order.status &&
        order.status.toLowerCase() === "confirmed")
  );
  const actionTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        <div className="edit_icon fz16">
          <i
            className="fa-light fa-edit me-3 fonticon"
            onClick={() => editProduct(rowData)}
          ></i>
          <i
            className="fa-light fa-trash fonticon"
            onClick={() => confirmDelete(rowData)}
          ></i>
        </div>
      </React.Fragment>
    );
  };

  const statusTemplate = (rowData) => {
    const statusClassName =
      rowData.status === "Confirmed" ? "status_cinf" : "status_pen";
    return <span className={statusClassName}>{rowData.status}</span>;
  };

  return (
    <>
      <div className="flex justify-content-center align-items-center mb-4 gap-2 d-none">
        <InputSwitch
          inputId="input-rowclick"
          checked={rowClick}
          onChange={(e) => setRowClick(e.value)}
        />
        <label htmlFor="input-rowclick">Row Click</label>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <DataTable
            selection={selectedProducts}
            onSelectionChange={(e) => setSelectedProducts(e.value)}
            value={isSearch ? filteredOrders : orders}
            scrollable
            scrollHeight="300"
            rows={20}
            paginator
            rowsPerPageOptions={[20, 25, 50]}
            dataKey="id"
            stripedRows
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "6rem" }}
            ></Column>
            <Column header="Action" body={actionTemplate} />
            <Column field="id" header="ID" sortable />
            <Column
              field="status"
              header="Status"
              body={statusTemplate}
              sortable
            />
            <Column field="orderdate" header="Order Date" sortable />
            <Column field="customer" header="Customer" sortable />
            <Column field="phone" header="Phone" sortable />
            <Column field="total" header="Total" sortable />
          </DataTable>
        </>
      )}

      <SliderMenu
        show={open}
        title="Update Orders"
        setShow={setOpen}
        placement="end"
        sliderWidth="500px"
        body={
          <AddOrders
            orderItem={orderItem}
            setShow={setOpen}
            setOpen={setOpen}
          />
        }
      />

      <DeleteConfirmationDialog
        title="Confirm Delete"
        message="Are you sure you want to delete this order?"
        onConfirm={handleDeleteConfirmation}
        onCancel={cancelItem}
        show={!!deleteTarget}
      />
    </>
  );
});

export default OrderList;
