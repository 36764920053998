import { iconClasses } from "@mui/material";
import { Receipt, Shipment, dashIcon, messsageCenter, orders, product, productbundles, settings } from "../components/Common/Icon";

export const menuItem=[
    {
        title:"DashBoard",
        url:"/dashboard",
        icon:dashIcon 
    },
    {
        title:"Orders",
        url:"/orders",
        icon:orders
        
    },
    {
        title:"Products",
        url:"/products",
        icon:product
    },
    {
        title:"Message Center",
        url:"/message-center",
        icon:messsageCenter
    },
    {
        title:"Product Bundles",
        url:"/product-bundles",
        icon:productbundles
    },
    {
        title:"Accounting",
        url:"/accounting",
        icon:Receipt
    },
    {
        title:"Settings",
        url:"/settings",
        icon:settings
    },
    {
        title:"Shipment",
        url:"/Shipment",
        icon:Shipment
    }

]