import React, {useState} from "react";
import {Offcanvas,Button} from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomSelect from "./CustomSelect";
import Selectcategories from "./Selectcategories";

export default function Filter() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    
    const options = [
        { label: 'All in caterories', value: 'All in caterories' },
        { label: 'Handheld barcode Scanner', value: 'Handheld barcode Scanner' },
        { label: 'Cordless Barcode Scanner', value: 'Cordless Barcode Scanner' },
        { label: '2D Barcode Scanner', value: '2D Barcode Scanner' },
      ];
   return(
        <>
            <Button variant="warning" onClick={handleShow} className="btn btn-gradient blurDropdownBg fz14 shadow-none border btn"><i className="fa-regular fa-filter"></i></Button>
            <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header className="border-bottom px-4 mb-4" closeButton>
            <Offcanvas.Title className="fz20 "><i className="fa-regular fa-magnifying-glass"></i> Search</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="px-5">
              <div className="filter-wrap">
              <div className="form-group mb-3">
                <label className="mb-1 fz14 fw400" htmlFor="">Find results with</label>
                <input className="form-control" />
            </div>
            <div className="form-group mb-3">
                <label className="mb-1 fz14 fw400" htmlFor="">Price ($)</label>
                <div className="d-flex price-field">
                <input className="form-control" />
                <input className="form-control" />
                </div>
            </div>
            <div className="form-group mb-3">
                <label className="mb-1 fz14 fw400" htmlFor="">Search in category</label>
                <div className="select-catg-wrap border">
                <CustomSelect options={options} onChange={(value)=>{}} />
                <Selectcategories/>
                </div>
            </div>
            <div className="form-group mt-4 d-flex justify-content-between align-items-center offcanvsfooter">
                <Button variant="warning" className="btn btn-gradient blurDropdownBg fz14 shadow-none border btn">Search</Button>
                <Link>Advanced Search</Link>
            </div>
              </div>
            </Offcanvas.Body>
            
        </Offcanvas>  
      </>
   ) 
}