import React from "react";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement,
  ArcElement,
} from "chart.js";

export const LineChart = ({ chartData }) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement
  );

  return (
    <div className="chart">
      <Line data={chartData} className="line_ca" />
    </div>
  );
};

export const PieCharts = ({ chartData }) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    PointElement,
    LineElement,
    ArcElement
  );

  return (
    <div className="chart mt-2">
      <Doughnut data={chartData}  className="char_size" />
    </div>
  );
};
