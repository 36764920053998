import React, { useState } from "react";
import {  NavLink } from "react-router-dom";
import mobileview from "../../assets/img/appjiilogo.png";
import { menuItem } from "../../services/MenuItem";
import AppItem from "./AppItem";
import { SliderMenu } from "../Common/SliderMenu";

export default function MobileMenu({handleMenuItemClick}) {

  const [show, setShow] = useState(false);
  const [closeIcon, setCloseIcon] = useState(false);
  const handleOpen = () => {
    setShow(!show);
    setCloseIcon(true);
  };

  return (
    <aside id="">
      <div className="position-relative">
        <div className="d-flex">
          <div className="sidebar-sec-nav">
            <span className="fz16 dropdown-toggle-none border-0">
              <i className="fa-light fa-grid-round grip-dot app_menu d-none"></i>
            </span>
     
          </div>
          <div className="logo py-sm-2 px-3 toogle_menu" onClick={handleOpen}>
            <img src={mobileview} className="mobile-logo" alt="" />
              <SliderMenu
              show={show}
              setShow={setShow}
              placement="start"
              sliderWidth="250px"
              closeIcon={closeIcon}
              setCloseIcon={setCloseIcon}
              body={<AppItem />}
            />
          </div>
        </div>
         </div>
      <div className="nav-sidebar d-flex flex-column ">
        <nav className="flex-grow-1">
          <ul className="d-flex flex-column h-100">
            {menuItem?.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <li>
                    <NavLink to={item.url} aria-current="page" onClick={handleMenuItemClick}>
                      {" "}
                      <i className="fa-light">{item?.icon}</i>{" "}
                      <span> {item?.title}</span>
                    </NavLink>
                  </li>
                </React.Fragment>
              );
            })}

           
          </ul>
        </nav>
      </div>
    </aside>
  );
}
