import { createSlice } from "@reduxjs/toolkit";

export const Orders = createSlice({
  name: "orders",
  initialState: {
    searchData: "",
    isSearch:false
  },

  reducers: {
    searchUser: (state, action) => {
      state.searchData = action.payload;
      state.isSearch=true
    },
  },

 
});

export default Orders.reducer;

export const { searchUser } = Orders.actions;
