import toogleSlice from "../Slice/toogleSlice";

import { configureStore } from '@reduxjs/toolkit';
import { OrderApi } from "../Action/ordersAction";
import {ProductApi} from "../Action/ProductAction"
import SearchSlice from "../Slice/SearchSlice";
import { setupListeners } from "@reduxjs/toolkit/query";
import AuthSlice from "../Slice/AuthSlice";

export const store= configureStore({
  reducer: {
    auth: AuthSlice,
    toogle:toogleSlice,
    orders:SearchSlice,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(OrderApi.middleware,ProductApi.middleware),
});
setupListeners(store.dispatch)