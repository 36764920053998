import { InputText } from "primereact/inputtext";
import Dropdown from "react-bootstrap/Dropdown";
export const DropDownMenu = ({
  options,
  isOpen,
  searchOrder,
  handleSearchOrder,
  toggleDropdown,
  handleOrderChange,
  selectOrderValue
}) => {
  return (
    <Dropdown
      show={isOpen}
      onToggle={toggleDropdown}
      className="custom-dessign-dropdown"
     >
      <Dropdown.Toggle
        variant=""
        id="dropdown-basic"
        className="departed-dropdown mx-sm-2 border "
      >
        {selectOrderValue}
      </Dropdown.Toggle>
      <Dropdown.Menu className="pb-0 animates slideIns">
        <div className="form-group border-bottom pb-2 px-2 py-2 position-relative">
          <InputText
            type="text"
            placeholder="Search"
            className="form-control"
            value={searchOrder}
            onChange={handleSearchOrder}
          />
          <button className="insideSubmitBtn fz14 bg-transparent border-0 position-absolute">
            <i className="fa-light fa-search"></i>
          </button>
        </div>

        {options?.map((option, index) => (
          <Dropdown.Item
            key={index}
            eventKey={option.value}
            className="menu_list pb-2"
            onClick={() => handleOrderChange(option.name)}
          >
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
