
 import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const OrderApi = createApi({
  reducerPath: 'order',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://665d3654e88051d60405b233.mockapi.io/' }),
  tagTypes: ['order'],
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => 'orders',
      providesTags: ['order'],
      transformResponse: (response) => response.sort((a, b) => b.id - a.id),
    }),
    createOrder: builder.mutation({
      query: (data) => ({
        url: 'orders',
        method: 'POST',
        body: data,
        headers: {
          'Content-type': 'application/json'
        },
      }),
      invalidatesTags: ['order'],
    }),
    updateOrder: builder.mutation({
      query: (data) => {
        // const { id } = data
        return {
          url: `orders/${data.id}`,
          method: 'PUT',
          body:data,
          headers: {
            'Content-type': 'application/json'
          },
        }
      },
      invalidatesTags: ['order'],
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `orders/${id}`,
        method: 'DELETE',
        headers: {
          'Content-type': 'application/json'
        },
      }),
      invalidatesTags: ['order'],
    }),
  }),
})

export const { useGetOrdersQuery, useCreateOrderMutation, useUpdateOrderMutation, useDeleteOrderMutation } = OrderApi;